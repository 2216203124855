import React from 'react'
import "./Reservas.css";

export const Reservas = () => {
  return (
    <div className='reservas-container'>
        <h1>Reserva una hora con nosotros.</h1>
        <div className="timeTaker">
            
        </div>
    </div>
  )
}
